<template>
  <fullscreen v-model="isFullScreen" style="background-color: #f0f2f5">
    <a-row :gutter="[8, 8]">
      <a-col :span="24">
        <div style="display: flex; align-items: center; padding: 0 16px; background-color: #fff">
          <div style="flex: 1; text-align: left; font-size: 16px">{{ $t("统计日期") }}: {{ statsDate }}</div>
          <div style="flex: 1; text-align: center; font-size: 28px; font-weight: bold; margin: 4px">
            {{ $t("WMS仓库综合看板") }}
            <a-button type="primary" style="float: right; margin-top: 5px" @click="$router.push('/kanban/warehouse_kanban')">
              大屏看板
            </a-button>
          </div>
          <div style="flex: 1; font-size: 16px">
            <div style="display: flex; justify-content: flex-end; align-items: center">
              <div style="margin: 0 8px">{{ $t("当前时间") }}: {{ currentTime }}</div>
              <div style="cursor: pointer" @click="toggleFullScreen">
                <a-icon v-if="isFullScreen" type="fullscreen-exit" style="font-size: 24px" />
                <a-icon v-else type="fullscreen" style="font-size: 24px" />
              </div>
            </div>
          </div>
        </div>
      </a-col>

      <div v-for="moduleItem of homeConfig" :key="moduleItem.module">
        <a-col v-if="moduleItem.module === '实时库存统计' && moduleItem.enable" :span="8">
          <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
            <template slot="title">
              {{ $t("实时库存统计") }}
            </template>

            <div style="display: flex; align-items: center; text-align: center; height: 100%">
              <div style="flex: 1">
                <div style="font-weight: bold">
                  <span style="font-size: 24px; color: #1890ff">
                    {{ dataItem.inventory_total_quantity.toFixed(2) }}
                  </span>
                  <span style="font-size: 14px">件</span>
                </div>
                <div style="font-size: 14px; color: #666">{{ $t("实时库存总数") }}</div>
              </div>
            </div>
          </a-card>
        </a-col>

        <a-col v-if="moduleItem.module === '库位状态' && moduleItem.enable" :span="8">
          <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
            <template slot="title">
              {{ $t("库位状态") }}
            </template>

            <div style="display: flex; align-items: center; text-align: center; height: 100%">
              <div style="flex: 1">
                <div style="font-size: 24px; font-weight: bold; color: #a0d911">{{ dataItem.idle_location_count }}</div>
                <div style="font-size: 14px; color: #666">{{ $t("库位空闲") }}</div>
              </div>
              <div style="flex: 1">
                <div style="font-size: 24px; font-weight: bold">{{ dataItem.occupied_location_count }}</div>
                <div style="font-size: 14px; color: #666">{{ $t("库位占用") }}</div>
              </div>
              <div style="flex: 1">
                <div style="font-size: 24px; font-weight: bold">{{ dataItem.locationPercentage }}%</div>
                <div style="font-size: 14px; color: #666">{{ $t("利用率") }}</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col v-if="moduleItem.module === '产品库存前十占比' && moduleItem.enable" :span="8">
          <ProductStatsCard />
        </a-col>
        <a-col v-if="moduleItem.module === '今日入库单统计' && moduleItem.enable" :span="8">
          <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
            <template slot="title">
              {{ $t("今日入库单统计") }}
            </template>

            <div style="height: 140px; margin-top: 15px">
              <a-card-grid class="smallCard" :hoverable="false">
                <span class="number">{{ dataItem.finished_stock_in_quantity }}</span>
                <span class="intro">{{ $t("今日已完成") }}</span>
              </a-card-grid>
              <a-card-grid class="smallCard" :hoverable="false">
                <div style="cursor: pointer" @click="$router.push('/stock_in/receipt_task')">
                  <span class="number" style="color: #1890ff">{{ dataItem.unfinished_stock_in_quantity }}</span>
                  <span class="intro">{{ $t("待收货") }}</span>
                </div>
              </a-card-grid>
              <div style="margin-bottom: 24px">
                <a-progress :percent="dataItem.stockInPercentage" style="width: 100%; padding: 0px 36px" />
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col v-if="moduleItem.module === '今日上架单统计' && moduleItem.enable" :span="8">
          <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
            <template slot="title">
              {{ $t("今日上架单统计") }}
            </template>

            <div style="height: 140px; margin-top: 15px">
              <a-card-grid class="smallCard" :hoverable="false">
                <span class="number">{{ dataItem.finished_shelve_quantity }}</span>
                <span class="intro">{{ $t("今日已完成") }}</span>
              </a-card-grid>
              <a-card-grid class="smallCard" :hoverable="false">
                <div style="cursor: pointer" @click="$router.push('/stock_in/shelve_task')">
                  <span class="number" style="color: #1890ff">{{ dataItem.unfinished_shelve_quantity }}</span>
                  <span class="intro">{{ $t("待上架") }}</span>
                </div>
              </a-card-grid>
              <div style="margin-bottom: 24px">
                <a-progress :percent="dataItem.shelvePercentage" style="width: 100%; padding: 0px 36px" />
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col v-if="moduleItem.module === '今日拣货单统计' && moduleItem.enable" :span="8">
          <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
            <template slot="title">
              {{ $t("今日拣货单统计") }}
            </template>

            <div style="height: 140px; margin-top: 15px">
              <a-card-grid class="smallCard" :hoverable="false">
                <span class="number">{{ dataItem.finished_picking_quantity }}</span>
                <span class="intro">{{ $t("今日已完成") }}</span>
              </a-card-grid>
              <a-card-grid class="smallCard" :hoverable="false">
                <div style="cursor: pointer" @click="$router.push('/stock_out/picking_task')">
                  <span class="number" style="color: #ffa940">{{ dataItem.unfinished_picking_quantity }}</span>
                  <span class="intro">{{ $t("待拣货") }}</span>
                </div>
              </a-card-grid>
              <div style="margin-bottom: 24px">
                <a-progress
                  :percent="dataItem.pickingPercentage"
                  strokeColor="#ffa940"
                  style="width: 100%; padding: 0px 36px"
                />
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col v-if="moduleItem.module === '入库趋势图' && moduleItem.enable" :span="8">
          <StockInStatsCard />
        </a-col>
        <a-col v-if="moduleItem.module === '出库趋势图' && moduleItem.enable" :span="8">
          <StockOutStatsCard />
        </a-col>
        <a-col v-if="moduleItem.module === '库存趋势图' && moduleItem.enable" :span="8">
          <InventoryStatsCard />
        </a-col>
        <a-col v-if="moduleItem.module === '实时出入库量信息概览' && moduleItem.enable" :span="8">
          <RealTimeInventoryCard />
        </a-col>
      </div>
    </a-row>
  </fullscreen>
</template>

<script>
import { homeOverview } from "@/api/data";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    ProductStatsCard: () => import("./ProductStatsCard"),
    StockInStatsCard: () => import("./StockInStatsCard"),
    StockOutStatsCard: () => import("./StockOutStatsCard"),
    InventoryStatsCard: () => import("./InventoryStatsCard"),
    RealTimeInventoryCard: () => import("./RealTimeInventoryCard"),
  },
  data() {
    return {
      statsDate: moment().format("YYYY-MM-DD"),
      currentTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      updateInterval: null,
      isFullScreen: false,
      isReloadPage: true,

      dataItem: {
        inventory_total_quantity: 0,
        idle_location_count: 0,
        occupied_location_count: 0,
        locationPercentage: 0,
        finished_stock_in_quantity: 0,
        unfinished_stock_in_quantity: 0,
        stockInPercentage: 0,
        finished_shelve_quantity: 0,
        unfinished_shelve_quantity: 0,
        shelvePercentage: 0,
        finished_picking_quantity: 0,
        unfinished_picking_quantity: 0,
        pickingPercentage: 0,
      },
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    homeConfig() {
      return this.$store.state.user.homeConfig;
    },
  },
  methods: {
    queryData() {
      homeOverview({ warehouse: this.currentWarehouse }).then((data) => {
        const stockInTotal = data.finished_stock_in_quantity + data.unfinished_stock_in_quantity;
        data.stockInPercentage = NP.divide(data.finished_stock_in_quantity, stockInTotal, 0.01);
        data.stockInPercentage = NP.round(data.stockInPercentage, 0);

        const stockOutTotal = data.finished_stock_out_quantity + data.unfinished_stock_out_quantity;
        data.stockOutPercentage = NP.divide(data.finished_stock_out_quantity, stockOutTotal, 0.01);
        data.stockOutPercentage = NP.round(data.stockOutPercentage, 0);

        const shelveTotal = data.finished_shelve_quantity + data.unfinished_shelve_quantity;
        data.shelvePercentage = NP.divide(data.finished_shelve_quantity, shelveTotal, 0.01);
        data.shelvePercentage = NP.round(data.shelvePercentage, 0);

        const pickingTotal = data.finished_picking_quantity + data.unfinished_picking_quantity;
        data.pickingPercentage = NP.divide(data.finished_picking_quantity, pickingTotal, 0.01);
        data.pickingPercentage = NP.round(data.pickingPercentage, 0);

        data.idle_location_count = NP.minus(data.total_location_count, data.occupied_location_count);
        data.locationPercentage = NP.divide(data.occupied_location_count, data.total_location_count, 0.01);
        data.locationPercentage = NP.round(data.locationPercentage, 0);
        this.dataItem = data;
      });
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.isReloadPage = true;
      setTimeout(() => (this.isReloadPage = false), 100);
    },
  },
  mounted() {
    setTimeout(() => (this.isReloadPage = false), 100);
    this.updateInterval = setInterval(() => (this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss")), 500);
    this.queryData();
  },
  beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  },
};
</script>

<style lang="less" scoped>
.smallCard {
  width: 50%;
  text-align: center;
  box-shadow: 0 0 0 #888888;
}
.chartTitle {
  font-size: 14px;
  font-weight: bold;
}
.number {
  font-size: 24px;
  height: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro {
  height: 50%;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}
.percent {
  height: 45px;
  font-size: 20px;
  text-align: left;
  color: #1890ff;
  &::before {
    content: "占用率:";
    font-size: 12px;
  }
  &::after {
    content: "%";
    font-size: 12px;
  }
}
</style>
